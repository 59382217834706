const plain_game = {
    word: "",
    plain_guesses: [],
    guesses: [],
    input_line: "",
    black_letters: new Set(),
    green_letters: new Set(),
    yellow_letters: new Set()
}

export default plain_game